<!-- start footer -->
<footer id="footer">
  <div class="container">
    <div class="footer-inner">
      <p class="copyright">© 2022-2023 SBMC.</p>
      <ul class="social-media">
        <li class="facebook"><a href="#" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="8.514"
              height="15.845" viewBox="0 0 8.514 15.845">
              <path id="facebook"
                d="M13.147,2.631H14.7V.112A21.552,21.552,0,0,0,12.437,0,3.465,3.465,0,0,0,8.66,3.723V5.942H6.187V8.758H8.66v7.087h3.032V8.759h2.373l.377-2.816H11.692V4c0-.814.236-1.371,1.455-1.371Z"
                transform="translate(-6.187)" fill="#162f56" />
            </svg></a></li>
        <li class="youtube"><a href="#" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" id="youtube"
              width="18.778" height="12.91" viewBox="0 0 18.778 12.91">
              <g id="Group_14" data-name="Group 14" transform="translate(0 0)">
                <path id="Path_8" data-name="Path 8"
                  d="M17.98,81.244c-.509-.906-1.062-1.073-2.188-1.136S11.841,80,9.391,80s-5.283.032-6.406.107-1.677.23-2.191,1.137S0,83.708,0,86.452v.009c0,2.732.27,4.3.795,5.2s1.067,1.07,2.19,1.147,3.953.1,6.407.1,5.277-.039,6.4-.1,1.678-.241,2.188-1.147c.529-.9.8-2.466.8-5.2v-.009C18.778,83.708,18.511,82.149,17.98,81.244ZM7.042,89.976V82.934l5.868,3.521Z"
                  transform="translate(0 -80)" fill="#162f56" />
              </g>
            </svg></a></li>
        <li class="twitter"><a href="#" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="18.778"
              height="15.261" viewBox="0 0 18.778 15.261">
              <path id="twitter"
                d="M5.987,11.437A10.887,10.887,0,0,0,16.949.475q0-.25-.011-.5a7.84,7.84,0,0,0,1.922-2,7.687,7.687,0,0,1-2.212.607,3.867,3.867,0,0,0,1.694-2.131,7.727,7.727,0,0,1-2.446.935,3.848,3.848,0,0,0-2.812-1.217A3.853,3.853,0,0,0,9.23.028a3.849,3.849,0,0,0,.1.879A10.939,10.939,0,0,1,1.388-3.119,3.844,3.844,0,0,0,.867-1.182,3.85,3.85,0,0,0,2.581,2.025,3.823,3.823,0,0,1,.836,1.543c0,.016,0,.032,0,.049A3.854,3.854,0,0,0,3.927,5.369,3.867,3.867,0,0,1,2.911,5.5a3.829,3.829,0,0,1-.724-.069,3.857,3.857,0,0,0,3.6,2.676A7.729,7.729,0,0,1,1,9.76a7.841,7.841,0,0,1-.919-.053,10.906,10.906,0,0,0,5.906,1.731"
                transform="translate(-0.082 3.824)" fill="#162f56" />
            </svg></a></li>
        <li class="instagram"><a href="#" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" id="instagram"
              width="15.261" height="15.261" viewBox="0 0 15.261 15.261">
              <path id="Path_9" data-name="Path 9"
                d="M11.129,0h-7A4.136,4.136,0,0,0,0,4.131v7A4.136,4.136,0,0,0,4.13,15.261h7A4.136,4.136,0,0,0,15.26,11.13v-7A4.136,4.136,0,0,0,11.129,0ZM7.63,11.8A4.173,4.173,0,1,1,11.8,7.631,4.178,4.178,0,0,1,7.63,11.8ZM11.9,4.442a1.233,1.233,0,1,1,1.233-1.233A1.234,1.234,0,0,1,11.9,4.442Zm0,0"
                transform="translate(0.001 0)" fill="#162f56" />
              <path id="Path_10" data-name="Path 10"
                d="M148.584,146.02a2.564,2.564,0,1,0,2.564,2.564A2.567,2.567,0,0,0,148.584,146.02Zm0,0"
                transform="translate(-140.954 -140.954)" fill="#162f56" />
              <path id="Path_11" data-name="Path 11"
                d="M388.331,96.3a.338.338,0,1,0,.338.338A.339.339,0,0,0,388.331,96.3Zm0,0"
                transform="translate(-376.427 -93.43)" fill="#162f56" />
            </svg></a></li>
        <li class="send"><a href="#" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="17.528"
              height="15.261" viewBox="0 0 17.528 15.261">
              <g id="XMLID_496_" transform="translate(0 -19.4)">
                <path id="XMLID_497_"
                  d="M.31,26.718l4.039,1.507,1.563,5.027a.475.475,0,0,0,.755.227l2.251-1.835a.672.672,0,0,1,.819-.023L13.8,34.57a.476.476,0,0,0,.746-.288l2.975-14.308a.476.476,0,0,0-.637-.541L.3,25.828A.476.476,0,0,0,.31,26.718Zm5.35.7,7.893-4.862a.138.138,0,0,1,.166.218L7.2,28.835a1.35,1.35,0,0,0-.419.808l-.222,1.644a.2.2,0,0,1-.4.029l-.853-3A.8.8,0,0,1,5.66,27.423Z"
                  transform="translate(0)" fill="#162f56" />
              </g>
            </svg></a></li>
        <li class="quora"><a href="#" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="14.613"
              height="15.261" viewBox="0 0 14.613 15.261">
              <g id="_x33_1.Quora" transform="translate(-11.7 -10)">
                <path id="Icon_66_"
                  d="M25.168,21.98s-.076,1.087-1.068,1.087c-.763,0-1.3-.591-1.793-1.354A6.727,6.727,0,0,0,24.6,16.639,6.544,6.544,0,0,0,18.148,10,6.544,6.544,0,0,0,11.7,16.639a6.544,6.544,0,0,0,6.448,6.639A5.873,5.873,0,0,0,20,22.991c.744,1.2,1.7,2.27,3.186,2.27,3.014,0,3.129-3.281,3.129-3.281Zm-7.02.229c-2.079,0-3.758-2.48-3.758-5.551s1.679-5.551,3.758-5.551,3.758,2.48,3.758,5.551a7.336,7.336,0,0,1-.725,3.262,3.431,3.431,0,0,0-2-1.545,4.179,4.179,0,0,0-3.319.706l.4.82a2.094,2.094,0,0,1,1.393,0c.63.153,1.126,1.011,1.679,2.022A2.7,2.7,0,0,1,18.148,22.209Z"
                  transform="translate(0 0)" fill="#162f56" />
              </g>
            </svg></a></li>
      </ul>
    </div>
  </div>
</footer>
<!-- End footer -->
