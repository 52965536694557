import { Component } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'csgpl-web';

  constructor( ) { }

  ngOnInit(): void { 
   //menu 
    $(document).ready(function () {
      $('.navbar-toggler').on('click', function (e) {
        $('.menuItem').addClass("show");
        $('body').addClass("offcanvas-menu");
        e.stopPropagation();
      });
    });

    $('.menuItem').click(function (e) {
      e.stopPropagation();
    });

    $('body, .menuItem ul li').click(function () {
      $('.menuItem').removeClass("show");
      $('body').removeClass("offcanvas-menu");
    });

   

  }
}
