<app-header></app-header>
<!-- start content -->
<div class="content">
  <div class="home-banner">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="banner-text">
            <h1>Investment in a <br> better way.</h1>
            <p>When an unknown printer took a galley of type and scrambled.</p>
            <ul class="score-list">
              <li class="score-tag"><span class="score">
                  <img src="assets/images/icons/india-flag.jpg">
                  SENSEX
                  <b class="downScore">-0.71%</b>
                </span></li>
              <li class="score-tag"><span class="score">
                  <img src="assets/images/icons/india-flag.jpg">
                  NIFTY
                  <b class="upScore">-0.71%</b>
                </span></li>
              <li class="score-tag"><span class="score">
                  <img src="assets/images/icons/bitcoin-flag.jpg">
                  BTCUSD
                  <b class="downScore">-0.71%</b>
                </span></li>
            </ul>
          </div>
        </div>
        <div class="col-md-6">
          <div class="demat-account">
            <h3>Open your<br>
              Demat Account</h3>
            <div class="form-group">
              <input class="form-control" type="email" placeholder="Email">
            </div>
            <div class="form-group">
              <div class="radio_label">
                <label class="radio-box">
                  <input type="radio" name="radio" value="radio">
                  <span>Motilal Oswal</span></label>
                <label class="radio-box">
                  <input type="radio" name="radio" value="radio">
                  <span>Aditya Birla Capital</span></label>
              </div>
            </div>
            <div class="form-group">
              <div class="checkbox_label">
                <label class="checkbox-box">
                  <input type="checkbox" name="checkbox" value="checkbox">
                  <span>I would like to open Account with Motilal Oswal Financial Services Ltd (MOFSL).</span></label>
              </div>
            </div>
            <div class="text-right">
              <button type="button" class="btn">Know more
                <svg xmlns="http://www.w3.org/2000/svg" id="layer1" width="11.868" height="7.59"
                  viewBox="0 0 11.868 7.59">
                  <path id="path9413"
                    d="M2.917,296.658h9.053l-2.076,2.076a.632.632,0,0,0,.894.894l1.89-1.893,1.262-1.264a.631.631,0,0,0,0-.889l-3.152-3.156a.632.632,0,1,0-.894.894l2.081,2.076H2.885a.632.632,0,1,0,.033,1.263Z"
                    transform="translate(-2.257 -292.234)" fill="#486af0" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="our-offerings">
    <div class="container">
      <span class="border-line"></span>
      <h2 class="section-title">Our Offerings</h2>
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="offerings-box">
            <span class="box-icon"><img src="assets/images/icons/icon1.png"></span>
            <h3>Portfolio Management</h3>
            <p>When an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
            <button type="button" class="btn-link">Know more
              <svg xmlns="http://www.w3.org/2000/svg" id="layer1" width="11.868" height="7.59"
                viewBox="0 0 11.868 7.59">
                <path id="path9413"
                  d="M2.917,296.658h9.053l-2.076,2.076a.632.632,0,0,0,.894.894l1.89-1.893,1.262-1.264a.631.631,0,0,0,0-.889l-3.152-3.156a.632.632,0,1,0-.894.894l2.081,2.076H2.885a.632.632,0,1,0,.033,1.263Z"
                  transform="translate(-2.257 -292.234)" fill="#486af0" />
              </svg>
            </button>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="offerings-box">
            <span class="box-icon"><img src="assets/images/icons/icon2.png"></span>
            <h3>Risk Advisory</h3>
            <p>When an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
            <button type="button" class="btn-link">Know more
              <svg xmlns="http://www.w3.org/2000/svg" id="layer1" width="11.868" height="7.59"
                viewBox="0 0 11.868 7.59">
                <path id="path9413"
                  d="M2.917,296.658h9.053l-2.076,2.076a.632.632,0,0,0,.894.894l1.89-1.893,1.262-1.264a.631.631,0,0,0,0-.889l-3.152-3.156a.632.632,0,1,0-.894.894l2.081,2.076H2.885a.632.632,0,1,0,.033,1.263Z"
                  transform="translate(-2.257 -292.234)" fill="#486af0" />
              </svg>
            </button>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="offerings-box">
            <span class="box-icon"><img src="assets/images/icons/icon3.png"></span>
            <h3>Equity / Derivatives</h3>
            <p>When an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
            <button type="button" class="btn-link">Know more
              <svg xmlns="http://www.w3.org/2000/svg" id="layer1" width="11.868" height="7.59"
                viewBox="0 0 11.868 7.59">
                <path id="path9413"
                  d="M2.917,296.658h9.053l-2.076,2.076a.632.632,0,0,0,.894.894l1.89-1.893,1.262-1.264a.631.631,0,0,0,0-.889l-3.152-3.156a.632.632,0,1,0-.894.894l2.081,2.076H2.885a.632.632,0,1,0,.033,1.263Z"
                  transform="translate(-2.257 -292.234)" fill="#486af0" />
              </svg>
            </button>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="offerings-box">
            <span class="box-icon"><img src="assets/images/icons/icon4.png"></span>
            <h3>Trading Strategy Building</h3>
            <p>When an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
            <button type="button" class="btn-link">Know more
              <svg xmlns="http://www.w3.org/2000/svg" id="layer1" width="11.868" height="7.59"
                viewBox="0 0 11.868 7.59">
                <path id="path9413"
                  d="M2.917,296.658h9.053l-2.076,2.076a.632.632,0,0,0,.894.894l1.89-1.893,1.262-1.264a.631.631,0,0,0,0-.889l-3.152-3.156a.632.632,0,1,0-.894.894l2.081,2.076H2.885a.632.632,0,1,0,.033,1.263Z"
                  transform="translate(-2.257 -292.234)" fill="#486af0" />
              </svg>
            </button>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="offerings-box">
            <span class="box-icon"><img src="assets/images/icons/icon5.png"></span>
            <h3>Mutual Funds</h3>
            <p>When an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
            <button type="button" class="btn-link">Know more
              <svg xmlns="http://www.w3.org/2000/svg" id="layer1" width="11.868" height="7.59"
                viewBox="0 0 11.868 7.59">
                <path id="path9413"
                  d="M2.917,296.658h9.053l-2.076,2.076a.632.632,0,0,0,.894.894l1.89-1.893,1.262-1.264a.631.631,0,0,0,0-.889l-3.152-3.156a.632.632,0,1,0-.894.894l2.081,2.076H2.885a.632.632,0,1,0,.033,1.263Z"
                  transform="translate(-2.257 -292.234)" fill="#486af0" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="appDownload">
    <div class="container">
      <div class="card-body">
        <div class="icon-card"><img src="assets/images/icons/card-logo.png" class="card-logo"></div>
        <h2>Download the SBMC App</h2>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
        <div class="aap-icon">
          <a href="#">
            <img src="assets/images/icons/aap-store.png">
          </a>
          <a href="#">
            <img src="assets/images/icons/google-play.png">
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="why-choose">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="whyChose-text">
            <h2 class="section-title">Why Choose SBMC</h2>
            <p>When an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
            <ul class="list-data">
              <li>
                <h4>Portfolio Management</h4>
                <p>When an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
              </li>
              <li>
                <h4>Portfolio Management</h4>
                <p>When an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
              </li>
            </ul>
            <button type="button" class="btn-link">Know more
              <svg xmlns="http://www.w3.org/2000/svg" id="layer1" width="11.868" height="7.59"
                viewBox="0 0 11.868 7.59">
                <path id="path9413"
                  d="M2.917,296.658h9.053l-2.076,2.076a.632.632,0,0,0,.894.894l1.89-1.893,1.262-1.264a.631.631,0,0,0,0-.889l-3.152-3.156a.632.632,0,1,0-.894.894l2.081,2.076H2.885a.632.632,0,1,0,.033,1.263Z"
                  transform="translate(-2.257 -292.234)" fill="#486af0" />
              </svg>
            </button>
          </div>
        </div>
        <div class="col-md-6">
          <div class="image-box"> <img src="assets/images/why-choose-img.jpg"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="latest-news">
    <div class="container">
      <h2 class="section-title">Latest News & Updates</h2>
      <div class="row">
        <div class="col-md-6">
          <div class="blog-post">
            <span class="thumbnail_post"><img src="assets/images/post-1.jpg"></span>
            <div class="post-data">
              <h2>Asian Stocks Down, Volatility Over Ukraine Tensions, Fed Policy Remains</h2>
              <div class="post-meta">
                <span class="date"><svg xmlns="http://www.w3.org/2000/svg" width="9" height="10" viewBox="0 0 9 10">
                    <g id="calendar" transform="translate(-65.744 -92.903)">
                      <path id="Path_61" data-name="Path 61"
                        d="M71.744,93.9v-.5a.5.5,0,0,1,1,0v.5a2,2,0,0,1,2,2v5a2,2,0,0,1-2,2h-5a2,2,0,0,1-2-2v-5a2,2,0,0,1,2-2v-.5a.5.5,0,0,1,1,0v.5Zm2,4h-7v3a1,1,0,0,0,1,1h5a1,1,0,0,0,1-1Zm-1-3v.5a.5.5,0,0,1-1,0v-.5h-3v.5a.5.5,0,0,1-1,0v-.5a1,1,0,0,0-1,1v1h7v-1A1,1,0,0,0,72.744,94.9Z"
                        fill="#818597" fill-rule="evenodd" />
                    </g>
                  </svg>
                  4 Feb, 2022</span>
                <span class="share">
                  <svg xmlns="http://www.w3.org/2000/svg" width="9.375" height="10" viewBox="0 0 9.375 10">
                    <g id="share_1_" data-name="share (1)" transform="translate(-16)">
                      <g id="Group_79" data-name="Group 79" transform="translate(16)">
                        <path id="Path_60" data-name="Path 60"
                          d="M23.617,6.484a1.751,1.751,0,0,0-1.41.718l-2.77-1.419a1.658,1.658,0,0,0-.052-1.148l2.9-1.745a1.748,1.748,0,1,0-.426-1.134,1.742,1.742,0,0,0,.122.632L19.074,4.14a1.755,1.755,0,1,0,.111,2.173l2.761,1.414a1.734,1.734,0,0,0-.086.516,1.758,1.758,0,1,0,1.758-1.758Z"
                          transform="translate(-16)" fill="#818597" />
                      </g>
                    </g>
                  </svg>
                  Share</span>
              </div>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s.</p>
              <button type="button" class="btn-link">Read more
                <svg xmlns="http://www.w3.org/2000/svg" id="layer1" width="11.868" height="7.59"
                  viewBox="0 0 11.868 7.59">
                  <path id="path9413"
                    d="M2.917,296.658h9.053l-2.076,2.076a.632.632,0,0,0,.894.894l1.89-1.893,1.262-1.264a.631.631,0,0,0,0-.889l-3.152-3.156a.632.632,0,1,0-.894.894l2.081,2.076H2.885a.632.632,0,1,0,.033,1.263Z"
                    transform="translate(-2.257 -292.234)" fill="#486af0" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="blog-post">
            <span class="thumbnail_post"><img src="assets/images/post-2.jpg"></span>
            <div class="post-data">
              <h2>Asian Stocks Down, Volatility Over Ukraine Tensions, Fed Policy Remains</h2>
              <div class="post-meta">
                <span class="date"><svg xmlns="http://www.w3.org/2000/svg" width="9" height="10" viewBox="0 0 9 10">
                    <g id="calendar" transform="translate(-65.744 -92.903)">
                      <path id="Path_61" data-name="Path 61"
                        d="M71.744,93.9v-.5a.5.5,0,0,1,1,0v.5a2,2,0,0,1,2,2v5a2,2,0,0,1-2,2h-5a2,2,0,0,1-2-2v-5a2,2,0,0,1,2-2v-.5a.5.5,0,0,1,1,0v.5Zm2,4h-7v3a1,1,0,0,0,1,1h5a1,1,0,0,0,1-1Zm-1-3v.5a.5.5,0,0,1-1,0v-.5h-3v.5a.5.5,0,0,1-1,0v-.5a1,1,0,0,0-1,1v1h7v-1A1,1,0,0,0,72.744,94.9Z"
                        fill="#818597" fill-rule="evenodd" />
                    </g>
                  </svg>
                  4 Feb, 2022</span>
                <span class="share">
                  <svg xmlns="http://www.w3.org/2000/svg" width="9.375" height="10" viewBox="0 0 9.375 10">
                    <g id="share_1_" data-name="share (1)" transform="translate(-16)">
                      <g id="Group_79" data-name="Group 79" transform="translate(16)">
                        <path id="Path_60" data-name="Path 60"
                          d="M23.617,6.484a1.751,1.751,0,0,0-1.41.718l-2.77-1.419a1.658,1.658,0,0,0-.052-1.148l2.9-1.745a1.748,1.748,0,1,0-.426-1.134,1.742,1.742,0,0,0,.122.632L19.074,4.14a1.755,1.755,0,1,0,.111,2.173l2.761,1.414a1.734,1.734,0,0,0-.086.516,1.758,1.758,0,1,0,1.758-1.758Z"
                          transform="translate(-16)" fill="#818597" />
                      </g>
                    </g>
                  </svg>
                  Share</span>
              </div>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s.</p>
              <button type="button" class="btn-link">Read more
                <svg xmlns="http://www.w3.org/2000/svg" id="layer1" width="11.868" height="7.59"
                  viewBox="0 0 11.868 7.59">
                  <path id="path9413"
                    d="M2.917,296.658h9.053l-2.076,2.076a.632.632,0,0,0,.894.894l1.89-1.893,1.262-1.264a.631.631,0,0,0,0-.889l-3.152-3.156a.632.632,0,1,0-.894.894l2.081,2.076H2.885a.632.632,0,1,0,.033,1.263Z"
                    transform="translate(-2.257 -292.234)" fill="#486af0" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End content -->
<app-footer></app-footer>
