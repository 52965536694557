  <!-- start header -->
  <header id="header">
    <div class="container">
      <div class="menu-row">
        <div class="logo-wrap">
          <a [routerLink]="['']" class="header-logo"> <img src="assets/images/logo.svg" alt="herfat"> </a>
        </div>
        <div class="menu-right">
          <button class="navbar-toggler" type="button">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" class="bi" fill="currentColor"
              viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
            </svg>
          </button>
         <div class="menuItem">
          <ul class="menu-list">
            <li class="active">Services</li>
            <li>About Us</li>
            <li>Strategy Builder</li>
            <li>Advisory Page </li>
          </ul>
         </div>
          <div class="login-sign" [routerLink]="['/login']" > 
            <img src="assets/images/icons/profile-user.svg" alt="Avatar" class="header-profile-user">
            Login / Sign up
          </div>
        
        </div>
      </div>
    </div>
  </header>
  <!-- End header -->
