  <!-- start header -->
  <header id="header">
    <div class="container">
      <div class="menu-row">
        <div class="logo-wrap">
          <a [routerLink]="['']" class="header-logo"> <img src="assets/images/logo-light.svg" alt="herfat"> </a>
        </div>
        <div class="menu-right">
          <div class="login-btn">
            <span>New to our site?</span>
            <button type="button" class="sign-up">Sign Up</button>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!-- End header -->


  <div class="author-login">
    <div class="container">
      <div class="card-body">
        <div class="form-inner">
          <div class="form_card">
            <div class="form_body">
              <ul ngbNav #nav="ngbNav" class="nav-tabs">
                <li ngbNavItem>
                  <a ngbNavLink>Login</a>
                  <ng-template ngbNavContent>
                        <!-- Login to Dashboard -->
                    <div class="form-data">
                      <h4>Login to Dashboard</h4>
                      <div class="form-group">
                        <input class="form-control" type="email" placeholder="Email">
                      </div>
                      <div class="form-group">
                        <input class="form-control" type="text" placeholder="Password">
                        <a href="#" class="forgot-password">Forgot password?</a>
                      </div>
                      <div class="form-group radio-group">
                        <div class="radio_label">
                          <label class="radio-box" [routerLink]="['/overall-summary']" >
                            <input type="radio" name="radio" value="radio">
                            <span>Retail Client</span></label>
                          <label class="radio-box"  [routerLink]="['/dashboard']" >
                            <input type="radio" name="radio" value="radio" checked>
                            <span>Business Partner</span></label>
                        </div>
                      </div>
                      <!-- <div class="form-group">
                                        <div class="checkbox_label">
                                          <label class="checkbox-box">
                                            <input type="checkbox" name="checkbox" value="checkbox">
                                            <span>I would like to open Account with Motilal Oswal Financial Services Ltd (MOFSL).</span></label>
                                        </div>
                                      </div> -->
                      <div class="text-right">
                        <button type="button" class="btn">Login
                          <svg xmlns="http://www.w3.org/2000/svg" id="layer1" width="11.868" height="7.59"
                            viewBox="0 0 11.868 7.59">
                            <path id="path9413"
                              d="M2.917,296.658h9.053l-2.076,2.076a.632.632,0,0,0,.894.894l1.89-1.893,1.262-1.264a.631.631,0,0,0,0-.889l-3.152-3.156a.632.632,0,1,0-.894.894l2.081,2.076H2.885a.632.632,0,1,0,.033,1.263Z"
                              transform="translate(-2.257 -292.234)" fill="#486af0" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </ng-template>
                </li>
                <li ngbNavItem>
                  <a ngbNavLink>Signup</a>
                  <ng-template ngbNavContent>
                      <!-- Sign up to create an account with us -->
                    <div class="form-data">
                      <h4>Sign up to create an account with us</h4>
                      <div class="form-group">
                        <input class="form-control" type="text" placeholder="Full name">
                      </div>

                      <div class="form-group">
                        <input class="form-control" type="email" placeholder="Email">
                      </div>
                      <div class="form-group">
                        <input class="form-control" type="text" placeholder="Password">
                      </div>
                      <div class="form-group">
                        <div class="checkbox_label">
                          <label class="checkbox-box">
                            <input type="checkbox" name="checkbox" value="checkbox">
                            <span>By signing up you agree to our <a href="#">privacy policy</a> and <a href="">terms of
                                use</a></span></label>
                        </div>
                      </div>
                      <div class="text-right">
                        <button type="button" class="btn">Sign Up
                          <svg xmlns="http://www.w3.org/2000/svg" id="layer1" width="11.868" height="7.59"
                            viewBox="0 0 11.868 7.59">
                            <path id="path9413"
                              d="M2.917,296.658h9.053l-2.076,2.076a.632.632,0,0,0,.894.894l1.89-1.893,1.262-1.264a.631.631,0,0,0,0-.889l-3.152-3.156a.632.632,0,1,0-.894.894l2.081,2.076H2.885a.632.632,0,1,0,.033,1.263Z"
                              transform="translate(-2.257 -292.234)" fill="#486af0" />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <!-- Verify your email -->
                    <div class="form-data d-none">
                      <h4>Verify your email</h4>
                      <p class="verification">A verification code has been sent to
                        <a href="#">ravishashtri86@gmail.com</a>
                      </p>
                      <div class="form-group">
                        <input class="form-control" type="text" placeholder="6 digit verification code">
                      </div>
                      <span class="receive">Didn't receive the code? <a href="#">Resend</a></span>
                      <div class="text-right">
                        <button type="button" class="btn">Verify Email
                          <svg xmlns="http://www.w3.org/2000/svg" id="layer1" width="11.868" height="7.59"
                            viewBox="0 0 11.868 7.59">
                            <path id="path9413"
                              d="M2.917,296.658h9.053l-2.076,2.076a.632.632,0,0,0,.894.894l1.89-1.893,1.262-1.264a.631.631,0,0,0,0-.889l-3.152-3.156a.632.632,0,1,0-.894.894l2.081,2.076H2.885a.632.632,0,1,0,.033,1.263Z"
                              transform="translate(-2.257 -292.234)" fill="#486af0" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </ng-template>
                </li>
              </ul>
              <div [ngbNavOutlet]="nav"></div>
            </div>
          </div>
          <div class="partners_card">
            <h3>We are partners with</h3>
            <p>50+ trading partners</p>
            <span class="partners-logo">
              <img src="/assets/images/motilaloswal-logo.jpg" class="partnerLogo">
              <img src="/assets/images/abc-logo.jpg" class="partnerLogo">
            </span>
            <blockquote class="returns">
              <h4>Clients who work with us get
                <b>10X returns</b>
              </h4>
            </blockquote>
            <p class="need-help">Need help? We are just a click away. <a href="#">Contact Us</a> </p>
          </div>
        </div>
      </div>
    </div>
  </div>
